<template>
  <!-- PROFILE BUTTON -->
  <li
    id="profileButton"
    m-dropdown-toggle="click"
    aria-expanded="true"
    class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
    @click="toggleDropdown()"
  >
    <div
      href="#"
      class="m-nav__link m-dropdown__toggle"
      style="cursor: pointer;"
    >
      <div
        v-if="store_getReadNoteLength && store_getReadNoteLength > 0"
        class="number"
      >
        {{ store_getReadNoteLength }}
      </div>
      <font-awesome-icon
        class="normalText gray"
        icon="bell"
        style="margin-top:15px;"
      />
    </div>
    <!-- PROFILE -->
    <div
      :class="[profileClass, 'm-dropdown__wrapper zIndexHigh']"
    >
      <span
        style="left:2px;"
        class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
      />
      <div class="m-dropdown__inner">
        <div
          class="m-dropdown__body"
          style="max-height: 250px;overflow: scroll;"
        >
          <div
            class="m-dropdown__content"
          >
            <ul
              v-if="notes && notes.length > 0"
              class="m-nav m-nav--skin-light"
            >
              <li
                v-for="(note, index) in notes"
                :key="`notes-${ index }`"
                class="m-nav__item dd-item"
                @click="openNote(note)"
              >
                <div
                  class="row"
                  style="margin-left: 1px; margin-right: 1px;"
                >
                  <div class="col-11">
                    <div
                      class="row"
                    >
                      <p>
                        {{ note.installationName }}
                      </p>
                      <p
                        style="word-wrap: break-word;"
                        v-html="note.message.length > 100 ? note.message.substring(0, 100) + '...' : note.message"
                      />
                      <p class="small-p">
                        {{ dateTime_dateTime(note.created) }}
                      </p>
                    </div>
                  </div>
                  <div class="col-1">
                    <font-awesome-icon
                      v-if="!note.read"
                      class="gray mt-1"
                      icon="envelope"
                    />
                    <font-awesome-icon
                      v-else
                      class="gray mt-1"
                      icon="envelope-open"
                    />
                  </div>
                </div>
              </li>
            </ul>
            <span v-else>No New Notifications</span>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { mapGetters } from 'vuex';

export default {
  name: "Notification",
  mixins: [
    authenticationMixin,
    dateTimeMixin
  ],
   data () {
    return {
      profileOpen: false,
      notes: null,
      notesInterval: null
    }
  },
  computed: {
    ...mapGetters([
      'store_getReadNoteLength'
    ]),
    profileClass: function () {
      if (this.profileOpen) return 'visible';
      return 'hidden';
    }
  },
  created () {
    this.createNotesInterval();
  },
  beforeDestroy () {
    this.clearNotesInterval();
  },
  methods: {
    openNote (note) {
      if(!note.read) {
        if(this.store_getReadNoteLength > 0) {
          this.$store.commit('store_setReadNoteLength', this.store_getReadNoteLength - 1);
        }
        note.read = true;
        this.setRead(note.id);
      }
      this.$router.push(`/installation/${note.installationId}/operation/${note.id}`)
    },
    createNotesInterval () {
      if (this.notesInterval != null) {
        this.clearNotesInterval();
      }
      this.getNotes();
      this.notesInterval = window.setInterval(() => {
        this.getNotes();
      }, 120000);
    },
    clearNotesInterval () {
      window.clearInterval(this.notesInterval);
      this.notesInterval = null;
    },
    setRead (id) {
      this.axios.post(`/Installation/SetNoteReadFlag?id=${ id }`);
    },
    getNotes () {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.axios.get(`/Installation/GetInstallationCommentsForUser?email=${ email }&isNotification=true`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.notes = response.data;
          var notesLength = this.notes.filter(x => !x.read).length;
          this.$store.commit('store_setReadNoteLength', notesLength);
        });
    },
    toggleDropdown () {
      this.profileOpen = !this.profileOpen;
    }
  }
}
</script>

<style scoped>
.number{
  height: 22px;
  width:  22px;
  background-color: #d63031;
  border-radius: 20px;
  color: white;
  text-align: center;
  position: absolute;
  top: 15px;
  left: 7px;
  border-style: solid;
  border-width: 2px;
}

.number:empty {
   display: none;
}

.m-dropdown.m-dropdown--arrow .m-dropdown__arrow:before, .m-dropdown.m-dropdown--arrow.m-dropdown--up .m-dropdown__arrow:before {
  left: 10px;
}

.dd-item {
  border-bottom: 1px black solid;;
}

.dd-item:hover {
  background-color: #e7e8ec;
}

.small-p {
  font-style: italic;
  color: gray;
}
</style>
